var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "el-main",
    [
      _c(
        "page-title",
        { attrs: { "show-back-btn": "" } },
        [
          _c(
            "template",
            { slot: "btn-inner" },
            [
              _vm.viewEdit()
                ? _c(
                    "debounce-button",
                    {
                      attrs: { type: "primary" },
                      on: { click: _vm.submitForms }
                    },
                    [_vm._v(" 提交 ")]
                  )
                : _vm._e()
            ],
            1
          )
        ],
        2
      ),
      _c("div", { staticClass: "partition-area" }, [
        _c(
          "div",
          {
            directives: [
              {
                name: "loading",
                rawName: "v-loading",
                value: _vm.loading.detail,
                expression: "loading.detail"
              }
            ],
            staticClass: "partition-area"
          },
          [
            _c(
              "el-form",
              {
                ref: "appForm",
                attrs: {
                  model: _vm.appForm,
                  rules: _vm.rules,
                  "label-width": "180px",
                  "label-suffix": _vm.constants.labelSuffix
                }
              },
              [
                _c(
                  "ics-page-inner",
                  { attrs: { title: "金融产品信息" } },
                  [
                    _vm.viewEdit()
                      ? _c(
                          "el-col",
                          { attrs: { span: 12 } },
                          [
                            _c(
                              "el-form-item",
                              {
                                attrs: {
                                  prop: "productCode",
                                  label: "资方金融产品"
                                }
                              },
                              [
                                _c(
                                  "ics-item-inner",
                                  {
                                    attrs: {
                                      prop: _vm.appForm.productCode,
                                      format: _vm.utils.isEffectiveCommon
                                    }
                                  },
                                  [
                                    _c(
                                      "el-select",
                                      {
                                        attrs: {
                                          placeholder: "请选择资方金融产品",
                                          filterable: ""
                                        },
                                        on: { change: _vm.productChange },
                                        model: {
                                          value: _vm.appForm.productCode,
                                          callback: function($$v) {
                                            _vm.$set(
                                              _vm.appForm,
                                              "productCode",
                                              $$v
                                            )
                                          },
                                          expression: "appForm.productCode"
                                        }
                                      },
                                      _vm._l(_vm.capProductList, function(
                                        item
                                      ) {
                                        return _c("el-option", {
                                          key: item.productCode,
                                          attrs: {
                                            label:
                                              item.productName +
                                              "（" +
                                              item.productCode +
                                              "）",
                                            value: item.productCode
                                          }
                                        })
                                      }),
                                      1
                                    )
                                  ],
                                  1
                                )
                              ],
                              1
                            )
                          ],
                          1
                        )
                      : _vm._e(),
                    _vm.viewEdit()
                      ? _c(
                          "el-col",
                          { attrs: { span: 12 } },
                          [
                            _c(
                              "el-form-item",
                              {
                                attrs: { prop: "coreId", label: "选择核心企业" }
                              },
                              [
                                _c(
                                  "ics-item-inner",
                                  {
                                    attrs: {
                                      prop: _vm.appForm.coreId,
                                      format: _vm.utils.isEffectiveCommon
                                    }
                                  },
                                  [
                                    _c(
                                      "el-select",
                                      {
                                        attrs: {
                                          placeholder: "请选择核心企业",
                                          filterable: ""
                                        },
                                        on: { change: _vm.companyChange },
                                        model: {
                                          value: _vm.appForm.coreId,
                                          callback: function($$v) {
                                            _vm.$set(_vm.appForm, "coreId", $$v)
                                          },
                                          expression: "appForm.coreId"
                                        }
                                      },
                                      _vm._l(_vm.coreList, function(item) {
                                        return _c("el-option", {
                                          key: item.coreId,
                                          attrs: {
                                            label:
                                              item.coreName +
                                              "（" +
                                              item.coreCode +
                                              "）",
                                            value: item.coreId
                                          }
                                        })
                                      }),
                                      1
                                    )
                                  ],
                                  1
                                )
                              ],
                              1
                            )
                          ],
                          1
                        )
                      : _vm._e(),
                    _c(
                      "el-col",
                      { attrs: { span: 12 } },
                      [
                        _c(
                          "el-form-item",
                          {
                            attrs: {
                              prop: "productName",
                              label: "场景方金融产品名称"
                            }
                          },
                          [
                            _c(
                              "ics-item-inner",
                              {
                                attrs: {
                                  prop: _vm.appForm.productName,
                                  format: _vm.utils.isEffectiveCommon
                                }
                              },
                              [
                                _c("el-input", {
                                  attrs: {
                                    placeholder: "请输入场景方金融产品名称"
                                  },
                                  model: {
                                    value: _vm.appForm.productName,
                                    callback: function($$v) {
                                      _vm.$set(_vm.appForm, "productName", $$v)
                                    },
                                    expression: "appForm.productName"
                                  }
                                })
                              ],
                              1
                            )
                          ],
                          1
                        )
                      ],
                      1
                    ),
                    _c("el-col"),
                    _c(
                      "div",
                      {
                        directives: [
                          {
                            name: "show",
                            rawName: "v-show",
                            value: _vm.showProductInfo,
                            expression: "showProductInfo"
                          }
                        ]
                      },
                      [
                        _c(
                          "el-col",
                          { attrs: { span: 12 } },
                          [
                            _c(
                              "el-form-item",
                              { attrs: { label: "金融产品编号" } },
                              [
                                _c("p", [
                                  _vm._v(
                                    _vm._s(
                                      _vm.utils.isEffectiveCommon(
                                        _vm.productInfo.productCode
                                      )
                                    )
                                  )
                                ])
                              ]
                            )
                          ],
                          1
                        ),
                        _c(
                          "el-col",
                          { attrs: { span: 12 } },
                          [
                            _c(
                              "el-form-item",
                              { attrs: { label: "隶属资金方" } },
                              [
                                _c("p", [
                                  _vm._v(
                                    _vm._s(
                                      _vm.utils.isEffectiveCommon(
                                        _vm.productInfo.capName
                                      )
                                    )
                                  )
                                ])
                              ]
                            )
                          ],
                          1
                        ),
                        _c(
                          "el-col",
                          { attrs: { span: 12 } },
                          [
                            _c(
                              "el-form-item",
                              { attrs: { label: "产品类型" } },
                              [
                                _c("p", [
                                  _vm._v(
                                    _vm._s(
                                      _vm.utils.statusFormat(
                                        Number(
                                          _vm.productInfo.productFinancing
                                        ),
                                        "productType"
                                      )
                                    )
                                  )
                                ])
                              ]
                            )
                          ],
                          1
                        ),
                        _c(
                          "el-col",
                          { attrs: { span: 12 } },
                          [
                            _c(
                              "el-form-item",
                              { attrs: { label: "产品模型" } },
                              [
                                _c("p", [
                                  _vm._v(
                                    _vm._s(
                                      _vm.utils.isEffectiveCommon(
                                        _vm.productInfo.productTypeName
                                      )
                                    )
                                  )
                                ])
                              ]
                            )
                          ],
                          1
                        ),
                        _c(
                          "el-col",
                          { attrs: { span: 12 } },
                          [
                            _c(
                              "el-form-item",
                              { attrs: { label: "上架状态" } },
                              [
                                _c("p", [
                                  _vm._v(
                                    _vm._s(
                                      _vm.utils.statusFormat(
                                        String(_vm.productInfo.state),
                                        "productState"
                                      )
                                    )
                                  )
                                ])
                              ]
                            )
                          ],
                          1
                        ),
                        _c(
                          "el-col",
                          { attrs: { span: 12 } },
                          [
                            _c(
                              "el-form-item",
                              {
                                attrs: {
                                  prop: "repaymentMethod",
                                  label: "是否支持线上放/还款"
                                }
                              },
                              [
                                _c(
                                  "ics-item-inner",
                                  {
                                    attrs: {
                                      prop: _vm.appForm.repaymentMethod,
                                      format: function(val) {
                                        return _vm.utils.statusFormat(
                                          Number(val),
                                          "isOnLine"
                                        )
                                      }
                                    }
                                  },
                                  [
                                    _c(
                                      "el-radio-group",
                                      {
                                        model: {
                                          value: _vm.appForm.repaymentMethod,
                                          callback: function($$v) {
                                            _vm.$set(
                                              _vm.appForm,
                                              "repaymentMethod",
                                              $$v
                                            )
                                          },
                                          expression: "appForm.repaymentMethod"
                                        }
                                      },
                                      _vm._l(_vm.constants.isOnLine, function(
                                        item
                                      ) {
                                        return _c(
                                          "el-radio",
                                          {
                                            key: item.value,
                                            attrs: { label: item.value }
                                          },
                                          [
                                            _vm._v(
                                              " " + _vm._s(item.label) + " "
                                            )
                                          ]
                                        )
                                      }),
                                      1
                                    )
                                  ],
                                  1
                                )
                              ],
                              1
                            )
                          ],
                          1
                        ),
                        _vm.appForm.repayment
                          ? _c(
                              "el-col",
                              { attrs: { span: 12 } },
                              [
                                _c(
                                  "el-form-item",
                                  {
                                    attrs: {
                                      prop: "repayment",
                                      label: "还款方式"
                                    }
                                  },
                                  [
                                    _c(
                                      "ics-item-inner",
                                      {
                                        attrs: {
                                          prop: _vm.appForm.repayment,
                                          format: function(val) {
                                            return _vm.utils.statusFormat(
                                              String(val),
                                              "repaymentType"
                                            )
                                          }
                                        }
                                      },
                                      [
                                        _c(
                                          "el-radio-group",
                                          {
                                            on: { change: _vm.changeRepayment },
                                            model: {
                                              value: _vm.appForm.repayment,
                                              callback: function($$v) {
                                                _vm.$set(
                                                  _vm.appForm,
                                                  "repayment",
                                                  $$v
                                                )
                                              },
                                              expression: "appForm.repayment"
                                            }
                                          },
                                          _vm._l(
                                            _vm.constants.repaymentType,
                                            function(item) {
                                              return _c(
                                                "el-radio",
                                                {
                                                  key: item.value,
                                                  attrs: { label: item.value }
                                                },
                                                [
                                                  _vm._v(
                                                    " " +
                                                      _vm._s(item.label) +
                                                      " "
                                                  )
                                                ]
                                              )
                                            }
                                          ),
                                          1
                                        )
                                      ],
                                      1
                                    )
                                  ],
                                  1
                                )
                              ],
                              1
                            )
                          : _vm._e(),
                        _c(
                          "el-col",
                          { attrs: { span: 12 } },
                          [
                            _c(
                              "el-form-item",
                              {
                                attrs: {
                                  prop: "serviceChargeMethod",
                                  label: "服务费收取方式"
                                }
                              },
                              [
                                _c(
                                  "ics-item-inner",
                                  {
                                    attrs: {
                                      prop: _vm.appForm.serviceChargeMethod,
                                      format: function(val) {
                                        return _vm.utils.statusFormat(
                                          Number(val),
                                          "isOnLine"
                                        )
                                      }
                                    }
                                  },
                                  [
                                    _c(
                                      "el-radio-group",
                                      {
                                        model: {
                                          value:
                                            _vm.appForm.serviceChargeMethod,
                                          callback: function($$v) {
                                            _vm.$set(
                                              _vm.appForm,
                                              "serviceChargeMethod",
                                              $$v
                                            )
                                          },
                                          expression:
                                            "appForm.serviceChargeMethod"
                                        }
                                      },
                                      _vm._l(_vm.constants.isOnLine, function(
                                        item
                                      ) {
                                        return _c(
                                          "el-radio",
                                          {
                                            key: item.value,
                                            attrs: { label: item.value }
                                          },
                                          [
                                            _vm._v(
                                              " " + _vm._s(item.label) + " "
                                            )
                                          ]
                                        )
                                      }),
                                      1
                                    )
                                  ],
                                  1
                                )
                              ],
                              1
                            )
                          ],
                          1
                        ),
                        _vm.appForm.maxAmount
                          ? _c(
                              "el-col",
                              { attrs: { span: 12 } },
                              [
                                _c(
                                  "el-form-item",
                                  {
                                    attrs: {
                                      prop: "maxAmount",
                                      label: "借贷金额上限(元)"
                                    }
                                  },
                                  [
                                    _c(
                                      "ics-item-inner",
                                      {
                                        attrs: {
                                          prop: _vm.appForm.maxAmount,
                                          format: function(val) {
                                            return _vm.utils.moneyFormat(
                                              Number(val),
                                              2
                                            )
                                          }
                                        }
                                      },
                                      [
                                        _c("el-input", {
                                          attrs: {
                                            type: "number",
                                            placeholder: "请输入借贷金额上限"
                                          },
                                          model: {
                                            value: _vm.appForm.maxAmount,
                                            callback: function($$v) {
                                              _vm.$set(
                                                _vm.appForm,
                                                "maxAmount",
                                                $$v
                                              )
                                            },
                                            expression: "appForm.maxAmount"
                                          }
                                        })
                                      ],
                                      1
                                    )
                                  ],
                                  1
                                )
                              ],
                              1
                            )
                          : _vm._e(),
                        _vm.appForm.minCycleMin && _vm.appForm.minCycleMax
                          ? _c(
                              "el-col",
                              { attrs: { span: 12 } },
                              [
                                _c(
                                  "el-form-item",
                                  {
                                    attrs: {
                                      label: "免收费期限(天)",
                                      required: _vm.viewEdit()
                                    }
                                  },
                                  [
                                    _vm.viewEdit()
                                      ? _c(
                                          "el-row",
                                          [
                                            _c(
                                              "el-col",
                                              { attrs: { span: 11 } },
                                              [
                                                _c(
                                                  "el-form-item",
                                                  {
                                                    staticStyle: {
                                                      "margin-bottom": "0"
                                                    },
                                                    attrs: {
                                                      prop: "minCycleMin"
                                                    }
                                                  },
                                                  [
                                                    _c(
                                                      "ics-item-inner",
                                                      {
                                                        attrs: {
                                                          prop:
                                                            _vm.appForm
                                                              .minCycleMin,
                                                          format:
                                                            _vm.utils
                                                              .isEffectiveCommon
                                                        }
                                                      },
                                                      [
                                                        _c("el-input", {
                                                          attrs: {
                                                            placeholder:
                                                              "请输入最小免收费期限"
                                                          },
                                                          on: {
                                                            input: function(v) {
                                                              return (_vm.appForm.minCycleMin = v.replace(
                                                                /\D|^0/g,
                                                                ""
                                                              ))
                                                            }
                                                          },
                                                          model: {
                                                            value:
                                                              _vm.appForm
                                                                .minCycleMin,
                                                            callback: function(
                                                              $$v
                                                            ) {
                                                              _vm.$set(
                                                                _vm.appForm,
                                                                "minCycleMin",
                                                                $$v
                                                              )
                                                            },
                                                            expression:
                                                              "appForm.minCycleMin"
                                                          }
                                                        })
                                                      ],
                                                      1
                                                    )
                                                  ],
                                                  1
                                                )
                                              ],
                                              1
                                            ),
                                            _c(
                                              "el-col",
                                              {
                                                staticStyle: {
                                                  "text-align": "center",
                                                  "line-height": "32px"
                                                },
                                                attrs: { span: 1 }
                                              },
                                              [_vm._v(" ~ ")]
                                            ),
                                            _c(
                                              "el-col",
                                              { attrs: { span: 11 } },
                                              [
                                                _c(
                                                  "el-form-item",
                                                  {
                                                    staticStyle: {
                                                      "margin-bottom": "0"
                                                    },
                                                    attrs: {
                                                      prop: "minCycleMax"
                                                    }
                                                  },
                                                  [
                                                    _c(
                                                      "ics-item-inner",
                                                      {
                                                        attrs: {
                                                          prop:
                                                            _vm.appForm
                                                              .minCycleMax,
                                                          format:
                                                            _vm.utils
                                                              .isEffectiveCommon
                                                        }
                                                      },
                                                      [
                                                        _c("el-input", {
                                                          attrs: {
                                                            placeholder:
                                                              "请输入最大免收费期限"
                                                          },
                                                          model: {
                                                            value:
                                                              _vm.appForm
                                                                .minCycleMax,
                                                            callback: function(
                                                              $$v
                                                            ) {
                                                              _vm.$set(
                                                                _vm.appForm,
                                                                "minCycleMax",
                                                                $$v
                                                              )
                                                            },
                                                            expression:
                                                              "appForm.minCycleMax"
                                                          }
                                                        })
                                                      ],
                                                      1
                                                    )
                                                  ],
                                                  1
                                                )
                                              ],
                                              1
                                            )
                                          ],
                                          1
                                        )
                                      : _vm._e(),
                                    !_vm.viewEdit()
                                      ? _c("p", [
                                          _vm._v(
                                            " " +
                                              _vm._s(
                                                _vm.utils.isEffectiveCommon(
                                                  _vm.appForm.minCycleMin
                                                ) +
                                                  " ~ " +
                                                  _vm.utils.isEffectiveCommon(
                                                    _vm.appForm.minCycleMax
                                                  )
                                              ) +
                                              " "
                                          )
                                        ])
                                      : _vm._e()
                                  ],
                                  1
                                )
                              ],
                              1
                            )
                          : _vm._e(),
                        _vm.appForm.maxPeriod &&
                        _vm.appForm.repayment !== "regular"
                          ? _c(
                              "el-col",
                              { attrs: { span: 12 } },
                              [
                                _c(
                                  "el-form-item",
                                  { attrs: { label: "借贷期限(天)" } },
                                  [
                                    _c(
                                      "ics-item-inner",
                                      {
                                        attrs: {
                                          prop: _vm.appForm.maxPeriod,
                                          format: _vm.utils.isEffectiveCommon
                                        }
                                      },
                                      [
                                        _c("el-input", {
                                          attrs: {
                                            placeholder: "请输入借贷期限"
                                          },
                                          on: {
                                            input: function(v) {
                                              return (_vm.appForm.maxPeriod = v.replace(
                                                /\D|^0/g,
                                                ""
                                              ))
                                            }
                                          },
                                          model: {
                                            value: _vm.appForm.maxPeriod,
                                            callback: function($$v) {
                                              _vm.$set(
                                                _vm.appForm,
                                                "maxPeriod",
                                                $$v
                                              )
                                            },
                                            expression: "appForm.maxPeriod"
                                          }
                                        })
                                      ],
                                      1
                                    )
                                  ],
                                  1
                                )
                              ],
                              1
                            )
                          : _vm._e(),
                        _vm.appForm.interestRateMin &&
                        _vm.appForm.interestRateMax
                          ? _c(
                              "el-col",
                              { attrs: { span: 12 } },
                              [
                                _c(
                                  "el-form-item",
                                  {
                                    attrs: {
                                      label: "日利率(%)",
                                      required: _vm.viewEdit()
                                    }
                                  },
                                  [
                                    _vm.viewEdit()
                                      ? _c(
                                          "el-row",
                                          [
                                            _c(
                                              "el-col",
                                              { attrs: { span: 11 } },
                                              [
                                                _c(
                                                  "el-form-item",
                                                  {
                                                    staticStyle: {
                                                      "margin-bottom": "0"
                                                    },
                                                    attrs: {
                                                      prop: "interestRateMin"
                                                    }
                                                  },
                                                  [
                                                    _c(
                                                      "ics-item-inner",
                                                      {
                                                        attrs: {
                                                          prop:
                                                            _vm.appForm
                                                              .interestRateMin,
                                                          format:
                                                            _vm.utils
                                                              .isEffectiveCommon
                                                        }
                                                      },
                                                      [
                                                        _c("el-input", {
                                                          attrs: {
                                                            placeholder:
                                                              "最小日利率"
                                                          },
                                                          model: {
                                                            value:
                                                              _vm.appForm
                                                                .interestRateMin,
                                                            callback: function(
                                                              $$v
                                                            ) {
                                                              _vm.$set(
                                                                _vm.appForm,
                                                                "interestRateMin",
                                                                $$v
                                                              )
                                                            },
                                                            expression:
                                                              "appForm.interestRateMin"
                                                          }
                                                        })
                                                      ],
                                                      1
                                                    )
                                                  ],
                                                  1
                                                )
                                              ],
                                              1
                                            ),
                                            _c(
                                              "el-col",
                                              {
                                                staticStyle: {
                                                  "text-align": "center",
                                                  "line-height": "32px"
                                                },
                                                attrs: { span: 1 }
                                              },
                                              [_vm._v(" ~ ")]
                                            ),
                                            _c(
                                              "el-col",
                                              { attrs: { span: 11 } },
                                              [
                                                _c(
                                                  "el-form-item",
                                                  {
                                                    staticStyle: {
                                                      "margin-bottom": "0"
                                                    },
                                                    attrs: {
                                                      prop: "interestRateMax"
                                                    }
                                                  },
                                                  [
                                                    _c(
                                                      "ics-item-inner",
                                                      {
                                                        attrs: {
                                                          prop:
                                                            _vm.appForm
                                                              .interestRateMax,
                                                          format:
                                                            _vm.utils
                                                              .isEffectiveCommon
                                                        }
                                                      },
                                                      [
                                                        _c("el-input", {
                                                          attrs: {
                                                            placeholder:
                                                              "最大日利率"
                                                          },
                                                          model: {
                                                            value:
                                                              _vm.appForm
                                                                .interestRateMax,
                                                            callback: function(
                                                              $$v
                                                            ) {
                                                              _vm.$set(
                                                                _vm.appForm,
                                                                "interestRateMax",
                                                                $$v
                                                              )
                                                            },
                                                            expression:
                                                              "appForm.interestRateMax"
                                                          }
                                                        })
                                                      ],
                                                      1
                                                    )
                                                  ],
                                                  1
                                                )
                                              ],
                                              1
                                            )
                                          ],
                                          1
                                        )
                                      : _vm._e(),
                                    !_vm.viewEdit()
                                      ? _c("p", [
                                          _vm._v(
                                            " " +
                                              _vm._s(
                                                _vm.utils.isEffectiveCommon(
                                                  _vm.appForm.interestRateMin
                                                ) +
                                                  " ~ " +
                                                  _vm.utils.isEffectiveCommon(
                                                    _vm.appForm.interestRateMax
                                                  )
                                              ) +
                                              " "
                                          )
                                        ])
                                      : _vm._e()
                                  ],
                                  1
                                )
                              ],
                              1
                            )
                          : _vm._e(),
                        _vm.appForm.penalTYtRateMin &&
                        _vm.appForm.penalTYtRateMax
                          ? _c(
                              "el-col",
                              { attrs: { span: 12 } },
                              [
                                _c(
                                  "el-form-item",
                                  {
                                    attrs: {
                                      label: "罚息(%)",
                                      required: _vm.viewEdit()
                                    }
                                  },
                                  [
                                    _vm.viewEdit()
                                      ? _c(
                                          "el-row",
                                          [
                                            _c(
                                              "el-col",
                                              { attrs: { span: 11 } },
                                              [
                                                _c(
                                                  "el-form-item",
                                                  {
                                                    staticStyle: {
                                                      "margin-bottom": "0"
                                                    },
                                                    attrs: {
                                                      prop: "penalTYtRateMin"
                                                    }
                                                  },
                                                  [
                                                    _c(
                                                      "ics-item-inner",
                                                      {
                                                        attrs: {
                                                          prop:
                                                            _vm.appForm
                                                              .penalTYtRateMin,
                                                          format:
                                                            _vm.utils
                                                              .isEffectiveCommon
                                                        }
                                                      },
                                                      [
                                                        _c("el-input", {
                                                          attrs: {
                                                            placeholder:
                                                              "请输入最小罚息"
                                                          },
                                                          model: {
                                                            value:
                                                              _vm.appForm
                                                                .penalTYtRateMin,
                                                            callback: function(
                                                              $$v
                                                            ) {
                                                              _vm.$set(
                                                                _vm.appForm,
                                                                "penalTYtRateMin",
                                                                $$v
                                                              )
                                                            },
                                                            expression:
                                                              "appForm.penalTYtRateMin"
                                                          }
                                                        })
                                                      ],
                                                      1
                                                    )
                                                  ],
                                                  1
                                                )
                                              ],
                                              1
                                            ),
                                            _c(
                                              "el-col",
                                              {
                                                staticStyle: {
                                                  "text-align": "center",
                                                  "line-height": "32px"
                                                },
                                                attrs: { span: 1 }
                                              },
                                              [_vm._v(" ~ ")]
                                            ),
                                            _c(
                                              "el-col",
                                              { attrs: { span: 11 } },
                                              [
                                                _c(
                                                  "el-form-item",
                                                  {
                                                    staticStyle: {
                                                      "margin-bottom": "0"
                                                    },
                                                    attrs: {
                                                      prop: "penalTYtRateMax"
                                                    }
                                                  },
                                                  [
                                                    _c(
                                                      "ics-item-inner",
                                                      {
                                                        attrs: {
                                                          prop:
                                                            _vm.appForm
                                                              .penalTYtRateMax,
                                                          format:
                                                            _vm.utils
                                                              .isEffectiveCommon
                                                        }
                                                      },
                                                      [
                                                        _c("el-input", {
                                                          attrs: {
                                                            placeholder:
                                                              "请输入最大罚息"
                                                          },
                                                          model: {
                                                            value:
                                                              _vm.appForm
                                                                .penalTYtRateMax,
                                                            callback: function(
                                                              $$v
                                                            ) {
                                                              _vm.$set(
                                                                _vm.appForm,
                                                                "penalTYtRateMax",
                                                                $$v
                                                              )
                                                            },
                                                            expression:
                                                              "appForm.penalTYtRateMax"
                                                          }
                                                        })
                                                      ],
                                                      1
                                                    )
                                                  ],
                                                  1
                                                )
                                              ],
                                              1
                                            )
                                          ],
                                          1
                                        )
                                      : _vm._e(),
                                    !_vm.viewEdit()
                                      ? _c("p", [
                                          _vm._v(
                                            " " +
                                              _vm._s(
                                                _vm.utils.isEffectiveCommon(
                                                  _vm.appForm.penalTYtRateMin
                                                ) +
                                                  " ~ " +
                                                  _vm.utils.isEffectiveCommon(
                                                    _vm.appForm.penalTYtRateMax
                                                  )
                                              ) +
                                              " "
                                          )
                                        ])
                                      : _vm._e()
                                  ],
                                  1
                                )
                              ],
                              1
                            )
                          : _vm._e(),
                        _vm.appForm.overdueMin && _vm.appForm.overdueMax
                          ? _c(
                              "el-col",
                              { attrs: { span: 12 } },
                              [
                                _c(
                                  "el-form-item",
                                  {
                                    attrs: {
                                      label: "逾期期限",
                                      required: _vm.viewEdit()
                                    }
                                  },
                                  [
                                    _vm.viewEdit()
                                      ? _c(
                                          "el-row",
                                          [
                                            _c(
                                              "el-col",
                                              { attrs: { span: 11 } },
                                              [
                                                _c(
                                                  "el-form-item",
                                                  {
                                                    staticStyle: {
                                                      "margin-bottom": "0"
                                                    },
                                                    attrs: {
                                                      prop: "overdueMin"
                                                    }
                                                  },
                                                  [
                                                    _c(
                                                      "ics-item-inner",
                                                      {
                                                        attrs: {
                                                          prop:
                                                            _vm.appForm
                                                              .overdueMin,
                                                          format:
                                                            _vm.utils
                                                              .isEffectiveCommon
                                                        }
                                                      },
                                                      [
                                                        _c("el-input", {
                                                          attrs: {
                                                            placeholder:
                                                              "请输入最小时长"
                                                          },
                                                          model: {
                                                            value:
                                                              _vm.appForm
                                                                .overdueMin,
                                                            callback: function(
                                                              $$v
                                                            ) {
                                                              _vm.$set(
                                                                _vm.appForm,
                                                                "overdueMin",
                                                                $$v
                                                              )
                                                            },
                                                            expression:
                                                              "appForm.overdueMin"
                                                          }
                                                        })
                                                      ],
                                                      1
                                                    )
                                                  ],
                                                  1
                                                )
                                              ],
                                              1
                                            ),
                                            _c(
                                              "el-col",
                                              {
                                                staticStyle: {
                                                  "text-align": "center",
                                                  "line-height": "32px"
                                                },
                                                attrs: { span: 1 }
                                              },
                                              [_vm._v(" ~ ")]
                                            ),
                                            _c(
                                              "el-col",
                                              { attrs: { span: 11 } },
                                              [
                                                _c(
                                                  "el-form-item",
                                                  {
                                                    staticStyle: {
                                                      "margin-bottom": "0"
                                                    },
                                                    attrs: {
                                                      prop: "overdueMax"
                                                    }
                                                  },
                                                  [
                                                    _c(
                                                      "ics-item-inner",
                                                      {
                                                        attrs: {
                                                          prop:
                                                            _vm.appForm
                                                              .overdueMax,
                                                          format:
                                                            _vm.utils
                                                              .isEffectiveCommon
                                                        }
                                                      },
                                                      [
                                                        _c("el-input", {
                                                          attrs: {
                                                            placeholder:
                                                              "请输入最大时长"
                                                          },
                                                          model: {
                                                            value:
                                                              _vm.appForm
                                                                .overdueMax,
                                                            callback: function(
                                                              $$v
                                                            ) {
                                                              _vm.$set(
                                                                _vm.appForm,
                                                                "overdueMax",
                                                                $$v
                                                              )
                                                            },
                                                            expression:
                                                              "appForm.overdueMax"
                                                          }
                                                        })
                                                      ],
                                                      1
                                                    )
                                                  ],
                                                  1
                                                )
                                              ],
                                              1
                                            )
                                          ],
                                          1
                                        )
                                      : _vm._e(),
                                    !_vm.viewEdit()
                                      ? _c("p", [
                                          _vm._v(
                                            " " +
                                              _vm._s(
                                                _vm.utils.isEffectiveCommon(
                                                  _vm.appForm.overdueMin
                                                ) +
                                                  " ~ " +
                                                  _vm.utils.isEffectiveCommon(
                                                    _vm.appForm.overdueMax
                                                  )
                                              ) +
                                              " "
                                          )
                                        ])
                                      : _vm._e()
                                  ],
                                  1
                                )
                              ],
                              1
                            )
                          : _vm._e(),
                        _vm.productInfo.roll
                          ? _c(
                              "el-col",
                              { attrs: { span: 12 } },
                              [
                                _c(
                                  "el-form-item",
                                  { attrs: { label: "是否支持宽限期" } },
                                  [
                                    _c("p", [
                                      _vm._v(
                                        _vm._s(
                                          _vm.utils.statusFormat(
                                            _vm.productInfo.roll,
                                            "rollType"
                                          )
                                        )
                                      )
                                    ])
                                  ]
                                )
                              ],
                              1
                            )
                          : _vm._e(),
                        _vm.productInfo.serviceChargeMethod
                          ? _c(
                              "el-col",
                              { attrs: { span: 12 } },
                              [
                                _c(
                                  "el-form-item",
                                  {
                                    attrs: {
                                      prop: "serviceChargeMethod",
                                      label: "服务费收取方式"
                                    }
                                  },
                                  [
                                    _c(
                                      "ics-item-inner",
                                      {
                                        attrs: {
                                          prop: _vm.appForm.serviceChargeMethod,
                                          format: function(val) {
                                            return _vm.utils.statusFormat(
                                              Number(val),
                                              "ownFunds"
                                            )
                                          }
                                        }
                                      },
                                      [
                                        _c(
                                          "el-radio-group",
                                          {
                                            model: {
                                              value:
                                                _vm.appForm.serviceChargeMethod,
                                              callback: function($$v) {
                                                _vm.$set(
                                                  _vm.appForm,
                                                  "serviceChargeMethod",
                                                  $$v
                                                )
                                              },
                                              expression:
                                                "appForm.serviceChargeMethod"
                                            }
                                          },
                                          _vm._l(
                                            _vm.constants.ownFunds,
                                            function(item) {
                                              return _c(
                                                "el-radio",
                                                {
                                                  key: item.value,
                                                  attrs: { label: item.value }
                                                },
                                                [
                                                  _vm._v(
                                                    " " +
                                                      _vm._s(item.label) +
                                                      " "
                                                  )
                                                ]
                                              )
                                            }
                                          ),
                                          1
                                        )
                                      ],
                                      1
                                    )
                                  ],
                                  1
                                )
                              ],
                              1
                            )
                          : _vm._e(),
                        _vm.appForm.maxExtensionMin &&
                        _vm.appForm.maxExtensionMax
                          ? _c(
                              "el-col",
                              { attrs: { span: 12 } },
                              [
                                _c(
                                  "el-form-item",
                                  {
                                    attrs: {
                                      label: "宽限期最大时长",
                                      required: _vm.viewEdit()
                                    }
                                  },
                                  [
                                    _vm.viewEdit()
                                      ? _c(
                                          "el-row",
                                          [
                                            _c(
                                              "el-col",
                                              { attrs: { span: 11 } },
                                              [
                                                _c(
                                                  "el-form-item",
                                                  {
                                                    staticStyle: {
                                                      "margin-bottom": "0"
                                                    },
                                                    attrs: {
                                                      prop: "maxExtensionMin"
                                                    }
                                                  },
                                                  [
                                                    _c(
                                                      "ics-item-inner",
                                                      {
                                                        attrs: {
                                                          prop:
                                                            _vm.appForm
                                                              .maxExtensionMin,
                                                          format:
                                                            _vm.utils
                                                              .isEffectiveCommon
                                                        }
                                                      },
                                                      [
                                                        _c("el-input", {
                                                          attrs: {
                                                            placeholder:
                                                              "请输入最小时长"
                                                          },
                                                          model: {
                                                            value:
                                                              _vm.appForm
                                                                .maxExtensionMin,
                                                            callback: function(
                                                              $$v
                                                            ) {
                                                              _vm.$set(
                                                                _vm.appForm,
                                                                "maxExtensionMin",
                                                                $$v
                                                              )
                                                            },
                                                            expression:
                                                              "appForm.maxExtensionMin"
                                                          }
                                                        })
                                                      ],
                                                      1
                                                    )
                                                  ],
                                                  1
                                                )
                                              ],
                                              1
                                            ),
                                            _c(
                                              "el-col",
                                              {
                                                staticStyle: {
                                                  "text-align": "center",
                                                  "line-height": "32px"
                                                },
                                                attrs: { span: 1 }
                                              },
                                              [_vm._v(" ~ ")]
                                            ),
                                            _c(
                                              "el-col",
                                              { attrs: { span: 11 } },
                                              [
                                                _c(
                                                  "el-form-item",
                                                  {
                                                    staticStyle: {
                                                      "margin-bottom": "0"
                                                    },
                                                    attrs: {
                                                      prop: "maxExtensionMax"
                                                    }
                                                  },
                                                  [
                                                    _c(
                                                      "ics-item-inner",
                                                      {
                                                        attrs: {
                                                          prop:
                                                            _vm.appForm
                                                              .maxExtensionMax,
                                                          format:
                                                            _vm.utils
                                                              .isEffectiveCommon
                                                        }
                                                      },
                                                      [
                                                        _c("el-input", {
                                                          attrs: {
                                                            placeholder:
                                                              "请输入最大时长"
                                                          },
                                                          model: {
                                                            value:
                                                              _vm.appForm
                                                                .maxExtensionMax,
                                                            callback: function(
                                                              $$v
                                                            ) {
                                                              _vm.$set(
                                                                _vm.appForm,
                                                                "maxExtensionMax",
                                                                $$v
                                                              )
                                                            },
                                                            expression:
                                                              "appForm.maxExtensionMax"
                                                          }
                                                        })
                                                      ],
                                                      1
                                                    )
                                                  ],
                                                  1
                                                )
                                              ],
                                              1
                                            )
                                          ],
                                          1
                                        )
                                      : _vm._e(),
                                    !_vm.viewEdit()
                                      ? _c("p", [
                                          _vm._v(
                                            " " +
                                              _vm._s(
                                                _vm.utils.isEffectiveCommon(
                                                  _vm.appForm.maxExtensionMin
                                                ) +
                                                  " ~ " +
                                                  _vm.utils.isEffectiveCommon(
                                                    _vm.appForm.maxExtensionMax
                                                  )
                                              ) +
                                              " "
                                          )
                                        ])
                                      : _vm._e()
                                  ],
                                  1
                                )
                              ],
                              1
                            )
                          : _vm._e(),
                        _vm.appForm.repayment === "regular"
                          ? _c(
                              "el-col",
                              { attrs: { span: 12 } },
                              [
                                _c(
                                  "el-form-item",
                                  { attrs: { label: "期数" } },
                                  [
                                    _c(
                                      "ics-item-inner",
                                      {
                                        attrs: {
                                          prop: _vm.appForm.periods,
                                          format: _vm.utils.isEffectiveCommon
                                        }
                                      },
                                      [
                                        _c("el-input", {
                                          attrs: { placeholder: "请输入期数" },
                                          model: {
                                            value: _vm.appForm.periods,
                                            callback: function($$v) {
                                              _vm.$set(
                                                _vm.appForm,
                                                "periods",
                                                $$v
                                              )
                                            },
                                            expression: "appForm.periods"
                                          }
                                        })
                                      ],
                                      1
                                    )
                                  ],
                                  1
                                )
                              ],
                              1
                            )
                          : _vm._e(),
                        _vm.appForm.extensionRateMin &&
                        _vm.appForm.extensionRateMax
                          ? _c(
                              "el-col",
                              { attrs: { span: 12 } },
                              [
                                _c(
                                  "el-form-item",
                                  {
                                    attrs: {
                                      label: "宽限期利率(%)",
                                      required: _vm.viewEdit()
                                    }
                                  },
                                  [
                                    _vm.viewEdit()
                                      ? _c(
                                          "el-row",
                                          [
                                            _c(
                                              "el-col",
                                              { attrs: { span: 11 } },
                                              [
                                                _c(
                                                  "el-form-item",
                                                  {
                                                    staticStyle: {
                                                      "margin-bottom": "0"
                                                    },
                                                    attrs: {
                                                      prop: "extensionRateMin"
                                                    }
                                                  },
                                                  [
                                                    _c(
                                                      "ics-item-inner",
                                                      {
                                                        attrs: {
                                                          prop:
                                                            _vm.appForm
                                                              .extensionRateMin,
                                                          format:
                                                            _vm.utils
                                                              .isEffectiveCommon
                                                        }
                                                      },
                                                      [
                                                        _c("el-input", {
                                                          attrs: {
                                                            placeholder:
                                                              "最小宽限期利率"
                                                          },
                                                          model: {
                                                            value:
                                                              _vm.appForm
                                                                .extensionRateMin,
                                                            callback: function(
                                                              $$v
                                                            ) {
                                                              _vm.$set(
                                                                _vm.appForm,
                                                                "extensionRateMin",
                                                                $$v
                                                              )
                                                            },
                                                            expression:
                                                              "appForm.extensionRateMin"
                                                          }
                                                        })
                                                      ],
                                                      1
                                                    )
                                                  ],
                                                  1
                                                )
                                              ],
                                              1
                                            ),
                                            _c(
                                              "el-col",
                                              {
                                                staticStyle: {
                                                  "text-align": "center",
                                                  "line-height": "32px"
                                                },
                                                attrs: { span: 1 }
                                              },
                                              [_vm._v(" ~ ")]
                                            ),
                                            _c(
                                              "el-col",
                                              { attrs: { span: 11 } },
                                              [
                                                _c(
                                                  "el-form-item",
                                                  {
                                                    staticStyle: {
                                                      "margin-bottom": "0"
                                                    },
                                                    attrs: {
                                                      prop: "extensionRateMax"
                                                    }
                                                  },
                                                  [
                                                    _c(
                                                      "ics-item-inner",
                                                      {
                                                        attrs: {
                                                          prop:
                                                            _vm.appForm
                                                              .extensionRateMax,
                                                          format:
                                                            _vm.utils
                                                              .isEffectiveCommon
                                                        }
                                                      },
                                                      [
                                                        _c("el-input", {
                                                          attrs: {
                                                            placeholder:
                                                              "最大宽限期利率"
                                                          },
                                                          model: {
                                                            value:
                                                              _vm.appForm
                                                                .extensionRateMax,
                                                            callback: function(
                                                              $$v
                                                            ) {
                                                              _vm.$set(
                                                                _vm.appForm,
                                                                "extensionRateMax",
                                                                $$v
                                                              )
                                                            },
                                                            expression:
                                                              "appForm.extensionRateMax"
                                                          }
                                                        })
                                                      ],
                                                      1
                                                    )
                                                  ],
                                                  1
                                                )
                                              ],
                                              1
                                            )
                                          ],
                                          1
                                        )
                                      : _vm._e(),
                                    !_vm.viewEdit()
                                      ? _c("p", [
                                          _vm._v(
                                            " " +
                                              _vm._s(
                                                _vm.utils.isEffectiveCommon(
                                                  _vm.appForm.extensionRateMin
                                                ) +
                                                  " ~ " +
                                                  _vm.utils.isEffectiveCommon(
                                                    _vm.appForm.extensionRateMax
                                                  )
                                              ) +
                                              " "
                                          )
                                        ])
                                      : _vm._e()
                                  ],
                                  1
                                )
                              ],
                              1
                            )
                          : _vm._e(),
                        _vm.appForm.financingMin && _vm.appForm.financingMax
                          ? _c(
                              "el-col",
                              { attrs: { span: 12 } },
                              [
                                _c(
                                  "el-form-item",
                                  {
                                    attrs: {
                                      label: "融资比例(%)",
                                      required: _vm.viewEdit()
                                    }
                                  },
                                  [
                                    _vm.viewEdit()
                                      ? _c(
                                          "el-row",
                                          [
                                            _c(
                                              "el-col",
                                              { attrs: { span: 11 } },
                                              [
                                                _c(
                                                  "el-form-item",
                                                  {
                                                    staticStyle: {
                                                      "margin-bottom": "0"
                                                    },
                                                    attrs: {
                                                      prop: "financingMin"
                                                    }
                                                  },
                                                  [
                                                    _c(
                                                      "ics-item-inner",
                                                      {
                                                        attrs: {
                                                          prop:
                                                            _vm.appForm
                                                              .financingMin,
                                                          format:
                                                            _vm.utils
                                                              .isEffectiveCommon
                                                        }
                                                      },
                                                      [
                                                        _c("el-input", {
                                                          attrs: {
                                                            placeholder:
                                                              "最小融资比例"
                                                          },
                                                          model: {
                                                            value:
                                                              _vm.appForm
                                                                .financingMin,
                                                            callback: function(
                                                              $$v
                                                            ) {
                                                              _vm.$set(
                                                                _vm.appForm,
                                                                "financingMin",
                                                                $$v
                                                              )
                                                            },
                                                            expression:
                                                              "appForm.financingMin"
                                                          }
                                                        })
                                                      ],
                                                      1
                                                    )
                                                  ],
                                                  1
                                                )
                                              ],
                                              1
                                            ),
                                            _c(
                                              "el-col",
                                              {
                                                staticStyle: {
                                                  "text-align": "center",
                                                  "line-height": "32px"
                                                },
                                                attrs: { span: 1 }
                                              },
                                              [_vm._v(" ~ ")]
                                            ),
                                            _c(
                                              "el-col",
                                              { attrs: { span: 11 } },
                                              [
                                                _c(
                                                  "el-form-item",
                                                  {
                                                    staticStyle: {
                                                      "margin-bottom": "0"
                                                    },
                                                    attrs: {
                                                      prop: "financingMax"
                                                    }
                                                  },
                                                  [
                                                    _c(
                                                      "ics-item-inner",
                                                      {
                                                        attrs: {
                                                          prop:
                                                            _vm.appForm
                                                              .financingMax,
                                                          format:
                                                            _vm.utils
                                                              .isEffectiveCommon
                                                        }
                                                      },
                                                      [
                                                        _c("el-input", {
                                                          attrs: {
                                                            placeholder:
                                                              "最大融资比例"
                                                          },
                                                          model: {
                                                            value:
                                                              _vm.appForm
                                                                .financingMax,
                                                            callback: function(
                                                              $$v
                                                            ) {
                                                              _vm.$set(
                                                                _vm.appForm,
                                                                "financingMax",
                                                                $$v
                                                              )
                                                            },
                                                            expression:
                                                              "appForm.financingMax"
                                                          }
                                                        })
                                                      ],
                                                      1
                                                    )
                                                  ],
                                                  1
                                                )
                                              ],
                                              1
                                            )
                                          ],
                                          1
                                        )
                                      : _vm._e(),
                                    !_vm.viewEdit()
                                      ? _c("p", [
                                          _vm._v(
                                            " " +
                                              _vm._s(
                                                _vm.utils.isEffectiveCommon(
                                                  _vm.appForm.financingMin
                                                ) +
                                                  " ~ " +
                                                  _vm.utils.isEffectiveCommon(
                                                    _vm.appForm.financingMax
                                                  )
                                              ) +
                                              " "
                                          )
                                        ])
                                      : _vm._e()
                                  ],
                                  1
                                )
                              ],
                              1
                            )
                          : _vm._e(),
                        _vm.appForm.serviceChargeMin &&
                        _vm.appForm.serviceChargeMax
                          ? _c(
                              "el-col",
                              { attrs: { span: 12 } },
                              [
                                _c(
                                  "el-form-item",
                                  {
                                    attrs: {
                                      label: "服务费比例(%)",
                                      required: _vm.viewEdit()
                                    }
                                  },
                                  [
                                    _vm.viewEdit()
                                      ? _c(
                                          "el-row",
                                          [
                                            _c(
                                              "el-col",
                                              { attrs: { span: 11 } },
                                              [
                                                _c(
                                                  "el-form-item",
                                                  {
                                                    staticStyle: {
                                                      "margin-bottom": "0"
                                                    },
                                                    attrs: {
                                                      prop: "serviceChargeMin"
                                                    }
                                                  },
                                                  [
                                                    _c(
                                                      "ics-item-inner",
                                                      {
                                                        attrs: {
                                                          prop:
                                                            _vm.appForm
                                                              .serviceChargeMin,
                                                          format:
                                                            _vm.utils
                                                              .isEffectiveCommon
                                                        }
                                                      },
                                                      [
                                                        _c("el-input", {
                                                          attrs: {
                                                            placeholder:
                                                              "最小服务费比例"
                                                          },
                                                          model: {
                                                            value:
                                                              _vm.appForm
                                                                .serviceChargeMin,
                                                            callback: function(
                                                              $$v
                                                            ) {
                                                              _vm.$set(
                                                                _vm.appForm,
                                                                "serviceChargeMin",
                                                                $$v
                                                              )
                                                            },
                                                            expression:
                                                              "appForm.serviceChargeMin"
                                                          }
                                                        })
                                                      ],
                                                      1
                                                    )
                                                  ],
                                                  1
                                                )
                                              ],
                                              1
                                            ),
                                            _c(
                                              "el-col",
                                              {
                                                staticStyle: {
                                                  "text-align": "center",
                                                  "line-height": "32px"
                                                },
                                                attrs: { span: 1 }
                                              },
                                              [_vm._v(" ~ ")]
                                            ),
                                            _c(
                                              "el-col",
                                              { attrs: { span: 11 } },
                                              [
                                                _c(
                                                  "el-form-item",
                                                  {
                                                    staticStyle: {
                                                      "margin-bottom": "0"
                                                    },
                                                    attrs: {
                                                      prop: "serviceChargeMax"
                                                    }
                                                  },
                                                  [
                                                    _c(
                                                      "ics-item-inner",
                                                      {
                                                        attrs: {
                                                          prop:
                                                            _vm.appForm
                                                              .serviceChargeMax,
                                                          format:
                                                            _vm.utils
                                                              .isEffectiveCommon
                                                        }
                                                      },
                                                      [
                                                        _c("el-input", {
                                                          attrs: {
                                                            placeholder:
                                                              "最大服务费比例"
                                                          },
                                                          model: {
                                                            value:
                                                              _vm.appForm
                                                                .serviceChargeMax,
                                                            callback: function(
                                                              $$v
                                                            ) {
                                                              _vm.$set(
                                                                _vm.appForm,
                                                                "serviceChargeMax",
                                                                $$v
                                                              )
                                                            },
                                                            expression:
                                                              "appForm.serviceChargeMax"
                                                          }
                                                        })
                                                      ],
                                                      1
                                                    )
                                                  ],
                                                  1
                                                )
                                              ],
                                              1
                                            )
                                          ],
                                          1
                                        )
                                      : _vm._e(),
                                    !_vm.viewEdit()
                                      ? _c("p", [
                                          _vm._v(
                                            " " +
                                              _vm._s(
                                                _vm.utils.isEffectiveCommon(
                                                  _vm.appForm.serviceChargeMin
                                                ) +
                                                  " ~ " +
                                                  _vm.utils.isEffectiveCommon(
                                                    _vm.appForm.serviceChargeMax
                                                  )
                                              ) +
                                              " "
                                          )
                                        ])
                                      : _vm._e()
                                  ],
                                  1
                                )
                              ],
                              1
                            )
                          : _vm._e(),
                        _vm.appForm.repayment === "regular"
                          ? _c(
                              "el-col",
                              { attrs: { span: 12 } },
                              [
                                _c(
                                  "el-form-item",
                                  { attrs: { label: "还款日" } },
                                  [
                                    _c(
                                      "ics-item-inner",
                                      {
                                        attrs: {
                                          prop: _vm.appForm.repaymentDay,
                                          format: _vm.utils.isEffectiveCommon
                                        }
                                      },
                                      [
                                        _c("el-input", {
                                          attrs: {
                                            placeholder: "请输入还款日"
                                          },
                                          model: {
                                            value: _vm.appForm.repaymentDay,
                                            callback: function($$v) {
                                              _vm.$set(
                                                _vm.appForm,
                                                "repaymentDay",
                                                $$v
                                              )
                                            },
                                            expression: "appForm.repaymentDay"
                                          }
                                        })
                                      ],
                                      1
                                    )
                                  ],
                                  1
                                )
                              ],
                              1
                            )
                          : _vm._e()
                      ],
                      1
                    )
                  ],
                  1
                ),
                _vm.showProductInfo
                  ? _c(
                      "ics-page-inner",
                      { attrs: { title: "业务覆盖城市" } },
                      [
                        _c("ics-city-inner", {
                          ref: "city",
                          attrs: {
                            "cap-id": _vm.productInfo.capId,
                            "city-info": _vm.cityList,
                            "view-city": !_vm.viewEdit()
                          }
                        })
                      ],
                      1
                    )
                  : _vm._e(),
                _vm.showProductInfo
                  ? _c("ics-page-inner", { attrs: { title: "绑定合同模板" } }, [
                      _c(
                        "div",
                        { staticClass: "partition-table" },
                        [
                          _c(
                            "el-table",
                            {
                              directives: [
                                {
                                  name: "loading",
                                  rawName: "v-loading",
                                  value: _vm.loadingTem.list,
                                  expression: "loadingTem.list"
                                }
                              ],
                              ref: "multipleTable",
                              staticClass: "table-input",
                              staticStyle: { width: "100%" },
                              attrs: {
                                data: _vm.templateList,
                                border: "",
                                "max-height": "360"
                              },
                              on: {
                                "selection-change": _vm.handleSelectionChange
                              }
                            },
                            [
                              _vm.editStatus()
                                ? _c("el-table-column", {
                                    attrs: {
                                      type: "selection",
                                      align: "left",
                                      "min-width": "50"
                                    }
                                  })
                                : _vm._e(),
                              _vm.viewEdit()
                                ? _c("el-table-column", {
                                    attrs: {
                                      prop: "contractTemplateNumber",
                                      label: "模板编号",
                                      "min-width": "140",
                                      formatter: _vm.utils.isEffective
                                    }
                                  })
                                : _vm._e(),
                              !_vm.viewEdit()
                                ? _c("el-table-column", {
                                    attrs: {
                                      prop: "templateCode",
                                      label: "模板编号",
                                      "min-width": "140",
                                      formatter: _vm.utils.isEffective
                                    }
                                  })
                                : _vm._e(),
                              _c("el-table-column", {
                                attrs: {
                                  prop: "contractName",
                                  label: "模板名称",
                                  "min-width": "140",
                                  formatter: _vm.utils.isEffective
                                }
                              }),
                              _c("el-table-column", {
                                attrs: {
                                  label: "模板文件",
                                  "min-width": "230"
                                },
                                scopedSlots: _vm._u(
                                  [
                                    {
                                      key: "default",
                                      fn: function(scope) {
                                        return [
                                          scope.row.contractUrl
                                            ? _c("span", [
                                                _c(
                                                  "a",
                                                  {
                                                    staticClass: "text-btn",
                                                    attrs: {
                                                      href: "javascript:"
                                                    },
                                                    on: {
                                                      click: function($event) {
                                                        return _vm.utils.downloadP(
                                                          "concat",
                                                          scope.row.contractUrl
                                                        )
                                                      }
                                                    }
                                                  },
                                                  [
                                                    _vm._v(
                                                      " " +
                                                        _vm._s(
                                                          _vm.utils.isEffectiveCommon(
                                                            scope.row
                                                              .contractUrlName
                                                          )
                                                        ) +
                                                        " "
                                                    )
                                                  ]
                                                )
                                              ])
                                            : _c("span", [_vm._v("-")])
                                        ]
                                      }
                                    }
                                  ],
                                  null,
                                  false,
                                  4021472612
                                )
                              }),
                              _c("el-table-column", {
                                attrs: {
                                  prop: "capName",
                                  label: "资金方",
                                  "min-width": "160",
                                  formatter: _vm.utils.isEffective
                                }
                              }),
                              _c("el-table-column", {
                                attrs: {
                                  prop: "createdBy",
                                  label: "创建人",
                                  "min-width": "150",
                                  formatter: _vm.utils.isEffective
                                }
                              }),
                              _c("el-table-column", {
                                attrs: {
                                  label: "签署方式",
                                  align: "center",
                                  "min-width": "190"
                                },
                                scopedSlots: _vm._u(
                                  [
                                    {
                                      key: "default",
                                      fn: function(scope) {
                                        return [
                                          _vm.editStatus()
                                            ? _c(
                                                "el-form-item",
                                                {
                                                  attrs: { "label-width": "0" },
                                                  scopedSlots: _vm._u(
                                                    [
                                                      {
                                                        key: "error",
                                                        fn: function(errScope) {
                                                          return [
                                                            _c(
                                                              "table-form-error-tooltip",
                                                              {
                                                                attrs: {
                                                                  "err-scope": errScope
                                                                }
                                                              }
                                                            )
                                                          ]
                                                        }
                                                      }
                                                    ],
                                                    null,
                                                    true
                                                  )
                                                },
                                                [
                                                  _c(
                                                    "el-radio-group",
                                                    {
                                                      model: {
                                                        value:
                                                          scope.row
                                                            .signingMethod,
                                                        callback: function(
                                                          $$v
                                                        ) {
                                                          _vm.$set(
                                                            scope.row,
                                                            "signingMethod",
                                                            $$v
                                                          )
                                                        },
                                                        expression:
                                                          "scope.row.signingMethod"
                                                      }
                                                    },
                                                    _vm._l(
                                                      _vm.constants
                                                        .signingMethodType,
                                                      function(item) {
                                                        return _c(
                                                          "el-radio",
                                                          {
                                                            key: item.value,
                                                            attrs: {
                                                              label: item.value
                                                            }
                                                          },
                                                          [
                                                            _vm._v(
                                                              " " +
                                                                _vm._s(
                                                                  item.label
                                                                ) +
                                                                " "
                                                            )
                                                          ]
                                                        )
                                                      }
                                                    ),
                                                    1
                                                  )
                                                ],
                                                1
                                              )
                                            : _c("span", [
                                                _vm._v(
                                                  _vm._s(
                                                    _vm.utils.statusFormat(
                                                      Number(
                                                        scope.row.signingMethod
                                                      ),
                                                      "signingMethodType"
                                                    )
                                                  )
                                                )
                                              ])
                                        ]
                                      }
                                    }
                                  ],
                                  null,
                                  false,
                                  3500685801
                                )
                              })
                            ],
                            1
                          )
                        ],
                        1
                      )
                    ])
                  : _vm._e()
              ],
              1
            )
          ],
          1
        )
      ])
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
<template>
  <el-main>
    <page-title show-back-btn>
      <template slot="btn-inner">
        <debounce-button v-if="viewEdit()" type="primary" @click="submitForms">
          提交
        </debounce-button>
      </template>
    </page-title>
    <div class="partition-area">
      <div v-loading="loading.detail" class="partition-area">
        <el-form ref="appForm" :model="appForm" :rules="rules" label-width="180px" :label-suffix="constants.labelSuffix">
          <ics-page-inner title="金融产品信息">
            <el-col v-if="viewEdit()" :span="12">
              <el-form-item prop="productCode" label="资方金融产品">
                <ics-item-inner :prop="appForm.productCode" :format="utils.isEffectiveCommon">
                  <el-select v-model="appForm.productCode" placeholder="请选择资方金融产品" filterable @change="productChange">
                    <el-option v-for="item in capProductList" :key="item.productCode" :label="`${item.productName}（${item.productCode}）`" :value="item.productCode" />
                  </el-select>
                </ics-item-inner>
              </el-form-item>
            </el-col>
            <el-col v-if="viewEdit()" :span="12">
              <el-form-item prop="coreId" label="选择核心企业">
                <ics-item-inner :prop="appForm.coreId" :format="utils.isEffectiveCommon">
                  <el-select v-model="appForm.coreId" placeholder="请选择核心企业" filterable @change="companyChange">
                    <el-option v-for="item in coreList" :key="item.coreId" :label="`${item.coreName}（${item.coreCode}）`" :value="item.coreId" />
                  </el-select>
                </ics-item-inner>
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item prop="productName" label="场景方金融产品名称">
                <ics-item-inner :prop="appForm.productName" :format="utils.isEffectiveCommon">
                  <el-input v-model="appForm.productName" placeholder="请输入场景方金融产品名称" />
                </ics-item-inner>
              </el-form-item>
            </el-col>
            <el-col />
            <div v-show="showProductInfo">
              <el-col :span="12">
                <el-form-item label="金融产品编号">
                  <p>{{ utils.isEffectiveCommon(productInfo.productCode) }}</p>
                </el-form-item>
              </el-col>
              <el-col :span="12">
                <el-form-item label="隶属资金方">
                  <p>{{ utils.isEffectiveCommon(productInfo.capName) }}</p>
                </el-form-item>
              </el-col>
              <el-col :span="12">
                <el-form-item label="产品类型">
                  <p>{{ utils.statusFormat(Number(productInfo.productFinancing), 'productType') }}</p>
                </el-form-item>
              </el-col>
              <el-col :span="12">
                <el-form-item label="产品模型">
                  <p>{{ utils.isEffectiveCommon(productInfo.productTypeName) }}</p>
                </el-form-item>
              </el-col>
              <el-col :span="12">
                <el-form-item label="上架状态">
                  <p>{{ utils.statusFormat(String(productInfo.state), 'productState') }}</p>
                </el-form-item>
              </el-col>
              <el-col :span="12">
                <el-form-item prop="repaymentMethod" label="是否支持线上放/还款">
                  <ics-item-inner :prop="appForm.repaymentMethod" :format="(val)=>utils.statusFormat(Number(val), 'isOnLine')">
                    <el-radio-group v-model="appForm.repaymentMethod">
                      <el-radio v-for="item in constants.isOnLine" :key="item.value" :label="item.value">
                        {{ item.label }}
                      </el-radio>
                    </el-radio-group>
                  </ics-item-inner>
                </el-form-item>
              </el-col>
              <el-col v-if="appForm.repayment" :span="12">
                <el-form-item prop="repayment" label="还款方式">
                  <ics-item-inner :prop="appForm.repayment" :format="(val)=>utils.statusFormat(String(val), 'repaymentType')">
                    <el-radio-group v-model="appForm.repayment" @change="changeRepayment">
                      <el-radio v-for="item in constants.repaymentType" :key="item.value" :label="item.value">
                        {{ item.label }}
                      </el-radio>
                    </el-radio-group>
                  </ics-item-inner>
                </el-form-item>
              </el-col>
              <el-col :span="12">
                <el-form-item prop="serviceChargeMethod" label="服务费收取方式">
                  <ics-item-inner :prop="appForm.serviceChargeMethod" :format="(val)=>utils.statusFormat(Number(val), 'isOnLine')">
                    <el-radio-group v-model="appForm.serviceChargeMethod">
                      <el-radio v-for="item in constants.isOnLine" :key="item.value" :label="item.value">
                        {{ item.label }}
                      </el-radio>
                    </el-radio-group>
                  </ics-item-inner>
                </el-form-item>
              </el-col>
              <el-col v-if="appForm.maxAmount" :span="12">
                <el-form-item prop="maxAmount" label="借贷金额上限(元)">
                  <ics-item-inner :prop="appForm.maxAmount" :format="(val)=>utils.moneyFormat(Number(val), 2)">
                    <el-input v-model="appForm.maxAmount" type="number" placeholder="请输入借贷金额上限" />
                  </ics-item-inner>
                </el-form-item>
              </el-col>
              <el-col v-if="appForm.minCycleMin && appForm.minCycleMax" :span="12">
                <el-form-item label="免收费期限(天)" :required="viewEdit()">
                  <el-row v-if="viewEdit()">
                    <el-col :span="11">
                      <el-form-item prop="minCycleMin" style="margin-bottom: 0;">
                        <ics-item-inner :prop="appForm.minCycleMin" :format="utils.isEffectiveCommon">
                          <el-input v-model="appForm.minCycleMin" placeholder="请输入最小免收费期限" @input="(v) => (appForm.minCycleMin = v.replace(/\D|^0/g,''))" />
                        </ics-item-inner>
                      </el-form-item>
                    </el-col>
                    <el-col :span="1" style="text-align:center;line-height:32px;">
                      ~
                    </el-col>
                    <el-col :span="11">
                      <el-form-item prop="minCycleMax" style="margin-bottom: 0;">
                        <ics-item-inner :prop="appForm.minCycleMax" :format="utils.isEffectiveCommon">
                          <el-input v-model="appForm.minCycleMax" placeholder="请输入最大免收费期限" />
                        </ics-item-inner>
                      </el-form-item>
                    </el-col>
                  </el-row>
                  <p v-if="!viewEdit()">
                    {{ (utils.isEffectiveCommon(appForm.minCycleMin) + ' ~ ' + utils.isEffectiveCommon(appForm.minCycleMax)) }}
                  </p>
                </el-form-item>
              </el-col>
              <el-col v-if="appForm.maxPeriod && appForm.repayment !== 'regular'" :span="12">
                <el-form-item label="借贷期限(天)">
                  <ics-item-inner :prop="appForm.maxPeriod" :format="utils.isEffectiveCommon">
                    <el-input v-model="appForm.maxPeriod" placeholder="请输入借贷期限" @input="(v) => (appForm.maxPeriod = v.replace(/\D|^0/g,''))" />
                  </ics-item-inner>
                </el-form-item>
              </el-col>
              <el-col v-if="appForm.interestRateMin && appForm.interestRateMax" :span="12">
                <el-form-item label="日利率(%)" :required="viewEdit()">
                  <el-row v-if="viewEdit()">
                    <el-col :span="11">
                      <el-form-item prop="interestRateMin" style="margin-bottom: 0;">
                        <ics-item-inner :prop="appForm.interestRateMin" :format="utils.isEffectiveCommon">
                          <el-input v-model="appForm.interestRateMin" placeholder="最小日利率" />
                        </ics-item-inner>
                      </el-form-item>
                    </el-col>
                    <el-col :span="1" style="text-align:center;line-height:32px;">
                      ~
                    </el-col>
                    <el-col :span="11">
                      <el-form-item prop="interestRateMax" style="margin-bottom: 0;">
                        <ics-item-inner :prop="appForm.interestRateMax" :format="utils.isEffectiveCommon">
                          <el-input v-model="appForm.interestRateMax" placeholder="最大日利率" />
                        </ics-item-inner>
                      </el-form-item>
                    </el-col>
                  </el-row>
                  <p v-if="!viewEdit()">
                    {{ (utils.isEffectiveCommon(appForm.interestRateMin) + ' ~ ' + utils.isEffectiveCommon(appForm.interestRateMax)) }}
                  </p>
                </el-form-item>
              </el-col>
              <el-col v-if="appForm.penalTYtRateMin && appForm.penalTYtRateMax" :span="12">
                <el-form-item label="罚息(%)" :required="viewEdit()">
                  <el-row v-if="viewEdit()">
                    <el-col :span="11">
                      <el-form-item prop="penalTYtRateMin" style="margin-bottom: 0;">
                        <ics-item-inner :prop="appForm.penalTYtRateMin" :format="utils.isEffectiveCommon">
                          <el-input v-model="appForm.penalTYtRateMin" placeholder="请输入最小罚息" />
                        </ics-item-inner>
                      </el-form-item>
                    </el-col>
                    <el-col :span="1" style="text-align:center;line-height:32px;">
                      ~
                    </el-col>
                    <el-col :span="11">
                      <el-form-item prop="penalTYtRateMax" style="margin-bottom: 0;">
                        <ics-item-inner :prop="appForm.penalTYtRateMax" :format="utils.isEffectiveCommon">
                          <el-input v-model="appForm.penalTYtRateMax" placeholder="请输入最大罚息" />
                        </ics-item-inner>
                      </el-form-item>
                    </el-col>
                  </el-row>
                  <p v-if="!viewEdit()">
                    {{ (utils.isEffectiveCommon(appForm.penalTYtRateMin) + ' ~ ' + utils.isEffectiveCommon(appForm.penalTYtRateMax)) }}
                  </p>
                </el-form-item>
              </el-col>
              <el-col v-if="appForm.overdueMin && appForm.overdueMax" :span="12">
                <el-form-item label="逾期期限" :required="viewEdit()">
                  <el-row v-if="viewEdit()">
                    <el-col :span="11">
                      <el-form-item prop="overdueMin" style="margin-bottom: 0;">
                        <ics-item-inner :prop="appForm.overdueMin" :format="utils.isEffectiveCommon">
                          <el-input v-model="appForm.overdueMin" placeholder="请输入最小时长" />
                        </ics-item-inner>
                      </el-form-item>
                    </el-col>
                    <el-col :span="1" style="text-align:center;line-height:32px;">
                      ~
                    </el-col>
                    <el-col :span="11">
                      <el-form-item prop="overdueMax" style="margin-bottom: 0;">
                        <ics-item-inner :prop="appForm.overdueMax" :format="utils.isEffectiveCommon">
                          <el-input v-model="appForm.overdueMax" placeholder="请输入最大时长" />
                        </ics-item-inner>
                      </el-form-item>
                    </el-col>
                  </el-row>
                  <p v-if="!viewEdit()">
                    {{ (utils.isEffectiveCommon(appForm.overdueMin) + ' ~ ' + utils.isEffectiveCommon(appForm.overdueMax)) }}
                  </p>
                </el-form-item>
              </el-col>
              <el-col v-if="productInfo.roll" :span="12">
                <el-form-item label="是否支持宽限期">
                  <p>{{ utils.statusFormat(productInfo.roll, 'rollType') }}</p>
                </el-form-item>
              </el-col>
              <el-col v-if="productInfo.serviceChargeMethod" :span="12">
                <el-form-item prop="serviceChargeMethod" label="服务费收取方式">
                  <ics-item-inner :prop="appForm.serviceChargeMethod" :format="(val)=>utils.statusFormat(Number(val), 'ownFunds')">
                    <el-radio-group v-model="appForm.serviceChargeMethod">
                      <el-radio v-for="item in constants.ownFunds" :key="item.value" :label="item.value">
                        {{ item.label }}
                      </el-radio>
                    </el-radio-group>
                  </ics-item-inner>
                </el-form-item>
              </el-col>
              <el-col v-if="appForm.maxExtensionMin && appForm.maxExtensionMax" :span="12">
                <el-form-item label="宽限期最大时长" :required="viewEdit()">
                  <el-row v-if="viewEdit()">
                    <el-col :span="11">
                      <el-form-item prop="maxExtensionMin" style="margin-bottom: 0;">
                        <ics-item-inner :prop="appForm.maxExtensionMin" :format="utils.isEffectiveCommon">
                          <el-input v-model="appForm.maxExtensionMin" placeholder="请输入最小时长" />
                        </ics-item-inner>
                      </el-form-item>
                    </el-col>
                    <el-col :span="1" style="text-align:center;line-height:32px;">
                      ~
                    </el-col>
                    <el-col :span="11">
                      <el-form-item prop="maxExtensionMax" style="margin-bottom: 0;">
                        <ics-item-inner :prop="appForm.maxExtensionMax" :format="utils.isEffectiveCommon">
                          <el-input v-model="appForm.maxExtensionMax" placeholder="请输入最大时长" />
                        </ics-item-inner>
                      </el-form-item>
                    </el-col>
                  </el-row>
                  <p v-if="!viewEdit()">
                    {{ (utils.isEffectiveCommon(appForm.maxExtensionMin) + ' ~ ' + utils.isEffectiveCommon(appForm.maxExtensionMax)) }}
                  </p>
                </el-form-item>
              </el-col>
              <el-col v-if="appForm.repayment === 'regular'" :span="12">
                <el-form-item label="期数">
                  <ics-item-inner :prop="appForm.periods" :format="utils.isEffectiveCommon">
                    <el-input v-model="appForm.periods" placeholder="请输入期数" />
                  </ics-item-inner>
                </el-form-item>
              </el-col>
              <el-col v-if="appForm.extensionRateMin && appForm.extensionRateMax" :span="12">
                <el-form-item label="宽限期利率(%)" :required="viewEdit()">
                  <el-row v-if="viewEdit()">
                    <el-col :span="11">
                      <el-form-item prop="extensionRateMin" style="margin-bottom: 0;">
                        <ics-item-inner :prop="appForm.extensionRateMin" :format="utils.isEffectiveCommon">
                          <el-input v-model="appForm.extensionRateMin" placeholder="最小宽限期利率" />
                        </ics-item-inner>
                      </el-form-item>
                    </el-col>
                    <el-col :span="1" style="text-align:center;line-height:32px;">
                      ~
                    </el-col>
                    <el-col :span="11">
                      <el-form-item prop="extensionRateMax" style="margin-bottom: 0;">
                        <ics-item-inner :prop="appForm.extensionRateMax" :format="utils.isEffectiveCommon">
                          <el-input v-model="appForm.extensionRateMax" placeholder="最大宽限期利率" />
                        </ics-item-inner>
                      </el-form-item>
                    </el-col>
                  </el-row>
                  <p v-if="!viewEdit()">
                    {{ (utils.isEffectiveCommon(appForm.extensionRateMin) + ' ~ ' + utils.isEffectiveCommon(appForm.extensionRateMax)) }}
                  </p>
                </el-form-item>
              </el-col>
              <el-col v-if="appForm.financingMin && appForm.financingMax" :span="12">
                <el-form-item label="融资比例(%)" :required="viewEdit()">
                  <el-row v-if="viewEdit()">
                    <el-col :span="11">
                      <el-form-item prop="financingMin" style="margin-bottom: 0;">
                        <ics-item-inner :prop="appForm.financingMin" :format="utils.isEffectiveCommon">
                          <el-input v-model="appForm.financingMin" placeholder="最小融资比例" />
                        </ics-item-inner>
                      </el-form-item>
                    </el-col>
                    <el-col :span="1" style="text-align:center;line-height:32px;">
                      ~
                    </el-col>
                    <el-col :span="11">
                      <el-form-item prop="financingMax" style="margin-bottom: 0;">
                        <ics-item-inner :prop="appForm.financingMax" :format="utils.isEffectiveCommon">
                          <el-input v-model="appForm.financingMax" placeholder="最大融资比例" />
                        </ics-item-inner>
                      </el-form-item>
                    </el-col>
                  </el-row>
                  <p v-if="!viewEdit()">
                    {{ (utils.isEffectiveCommon(appForm.financingMin) + ' ~ ' + utils.isEffectiveCommon(appForm.financingMax)) }}
                  </p>
                </el-form-item>
              </el-col>
              <el-col v-if="appForm.serviceChargeMin && appForm.serviceChargeMax" :span="12">
                <el-form-item label="服务费比例(%)" :required="viewEdit()">
                  <el-row v-if="viewEdit()">
                    <el-col :span="11">
                      <el-form-item prop="serviceChargeMin" style="margin-bottom: 0;">
                        <ics-item-inner :prop="appForm.serviceChargeMin" :format="utils.isEffectiveCommon">
                          <el-input v-model="appForm.serviceChargeMin" placeholder="最小服务费比例" />
                        </ics-item-inner>
                      </el-form-item>
                    </el-col>
                    <el-col :span="1" style="text-align:center;line-height:32px;">
                      ~
                    </el-col>
                    <el-col :span="11">
                      <el-form-item prop="serviceChargeMax" style="margin-bottom: 0;">
                        <ics-item-inner :prop="appForm.serviceChargeMax" :format="utils.isEffectiveCommon">
                          <el-input v-model="appForm.serviceChargeMax" placeholder="最大服务费比例" />
                        </ics-item-inner>
                      </el-form-item>
                    </el-col>
                  </el-row>
                  <p v-if="!viewEdit()">
                    {{ (utils.isEffectiveCommon(appForm.serviceChargeMin) + ' ~ ' + utils.isEffectiveCommon(appForm.serviceChargeMax)) }}
                  </p>
                </el-form-item>
              </el-col>
              <el-col v-if="appForm.repayment === 'regular'" :span="12">
                <el-form-item label="还款日">
                  <ics-item-inner :prop="appForm.repaymentDay" :format="utils.isEffectiveCommon">
                    <el-input v-model="appForm.repaymentDay" placeholder="请输入还款日" />
                  </ics-item-inner>
                </el-form-item>
              </el-col>
            </div>
          </ics-page-inner>
          <ics-page-inner v-if="showProductInfo" title="业务覆盖城市">
            <ics-city-inner ref="city" :cap-id="productInfo.capId" :city-info="cityList" :view-city="!viewEdit()" />
          </ics-page-inner>
          <ics-page-inner v-if="showProductInfo" title="绑定合同模板">
            <div class="partition-table">
              <el-table ref="multipleTable" v-loading="loadingTem.list" :data="templateList" border style="width: 100%" class="table-input" max-height="360" @selection-change="handleSelectionChange">
                <el-table-column v-if="editStatus()" type="selection" align="left" min-width="50" />
                <el-table-column v-if="viewEdit()" prop="contractTemplateNumber" label="模板编号" min-width="140" :formatter="utils.isEffective" />
                <el-table-column v-if="!viewEdit()" prop="templateCode" label="模板编号" min-width="140" :formatter="utils.isEffective" />
                <el-table-column prop="contractName" label="模板名称" min-width="140" :formatter="utils.isEffective" />
                <el-table-column label="模板文件" min-width="230">
                  <template v-slot="scope">
                    <span v-if="scope.row.contractUrl">
                      <a href="javascript:" class="text-btn" @click="utils.downloadP('concat', scope.row.contractUrl)">
                        {{ utils.isEffectiveCommon(scope.row.contractUrlName) }}
                      </a>
                    </span>
                    <span v-else>-</span>
                  </template>
                </el-table-column>
                <el-table-column prop="capName" label="资金方" min-width="160" :formatter="utils.isEffective" />
                <el-table-column prop="createdBy" label="创建人" min-width="150" :formatter="utils.isEffective" />
                <el-table-column label="签署方式" align="center" min-width="190">
                  <template v-slot="scope">
                    <el-form-item v-if="editStatus()" label-width="0">
                      <el-radio-group v-model="scope.row.signingMethod">
                        <el-radio v-for="item in constants.signingMethodType" :key="item.value" :label="item.value">
                          {{ item.label }}
                        </el-radio>
                      </el-radio-group>
                      <template slot="error" slot-scope="errScope">
                        <table-form-error-tooltip :err-scope="errScope" />
                      </template>
                    </el-form-item>
                    <span v-else>{{ utils.statusFormat(Number(scope.row.signingMethod), 'signingMethodType') }}</span>
                  </template>
                </el-table-column>
              </el-table>
            </div>
          </ics-page-inner>
        </el-form>
      </div>
    </div>
  </el-main>
</template>

<script>
import { routeEnterMixin } from '@/assets/js/mixins'
import IcsCityInner from '../components/city-inner'
export default {
  components: { IcsCityInner },
  mixins: [routeEnterMixin],
  data () {
    const validateMaxPeriod = (rule, value, callback) => {
      if (Number(value) < 1) {
        callback(new Error('免收费期限不能小于1'))
      } else {
        callback()
      }
    }
    const validateCycleMin = (rule, value, callback) => {
      if (Number(value) < 0) {
        callback(new Error('免收费期限不能小于0'))
      } else {
        callback()
      }
    }
    const validateCycleMax = (rule, value, callback) => {
      if (this.appForm.minCycleMin) {
        if (Number(this.appForm.minCycleMin) <= Number(value)) {
          callback()
        } else {
          callback(new Error('最大周期不能小于最小周期'))
        }
      } else {
        callback()
      }
    }
    const validateInterestMin = (rule, value, callback) => {
      if (Number(value) < 0) {
        callback(new Error('罚息不能小于0'))
      } else {
        callback()
      }
    }
    const validateInterestMax = (rule, value, callback) => {
      if (Number(value) > 100) {
        callback(new Error('罚息不能大于100'))
      } else {
        if (this.appForm.penalTYtRateMin) {
          if (Number(this.appForm.penalTYtRateMin) <= Number(value)) {
            callback()
          } else {
            callback(new Error('最大罚息低于最小罚息'))
          }
        } else {
          callback()
        }
      }
    }
    const validatePenaltytMin = (rule, value, callback) => {
      if (Number(value) < 0) {
        callback(new Error('日利率不能小于0'))
      } else {
        callback()
      }
    }
    const validatePenaltytMax = (rule, value, callback) => {
      if (Number(value) > 100) {
        callback(new Error('日利率不能大于100'))
      } else {
        if (this.appForm.interestRateMin) {
          if (Number(this.appForm.interestRateMin) <= Number(value)) {
            callback()
          } else {
            callback(new Error('最大日利率低于最小日利率'))
          }
        } else {
          callback()
        }
      }
    }
    const validateOverdueMin = (rule, value, callback) => {
      if (Number(value) < 0) {
        callback(new Error('最小逾期期限不能小于0'))
      } else {
        callback()
      }
    }
    const validateOverdueMax = (rule, value, callback) => {
      if (this.appForm.overdueMin) {
        if (Number(this.appForm.overdueMin) <= Number(value)) {
          callback()
        } else {
          callback(new Error('最大时长不能小于最小时长'))
        }
      } else {
        callback()
      }
    }
    const validateMaxExtensionMin = (rule, value, callback) => {
      if (Number(value) < 1) {
        callback(new Error('最小宽限期时长不能小于1'))
      } else {
        callback()
      }
    }
    const validateMaxExtensionMax = (rule, value, callback) => {
      if (this.appForm.maxExtensionMin) {
        if (Number(this.appForm.maxExtensionMin) <= Number(value)) {
          callback()
        } else {
          callback(new Error('最大时长低于最小时长'))
        }
      } else {
        callback()
      }
    }
    const validateExtensionRateMin = (rule, value, callback) => {
      if (Number(value) < 0) {
        callback(new Error('最小宽限期利率不能小于0'))
      } else {
        callback()
      }
    }
    const validateExtensionRateMax = (rule, value, callback) => {
      if (Number(value) > 100) {
        callback(new Error('最大利率不能大于100'))
      } else {
        if (this.appForm.extensionRateMin) {
          if (Number(this.appForm.extensionRateMin) <= Number(value)) {
            callback()
          } else {
            callback(new Error('最大利率低于最小利率'))
          }
        } else {
          callback()
        }
      }
    }
    const validateFinancingMin = (rule, value, callback) => {
      if (Number(value) < 0) {
        callback(new Error('最小融资比例不能小于0'))
      } else {
        callback()
      }
    }
    const validateFinancingMax = (rule, value, callback) => {
      if (Number(value) > 100) {
        callback(new Error('融资比例不能大于100'))
      } else {
        if (this.appForm.financingMin) {
          if (Number(this.appForm.financingMin) <= Number(value)) {
            callback()
          } else {
            callback(new Error('最大比例低于最小比例'))
          }
        } else {
          callback()
        }
      }
    }
    const validateServiceChargeMin = (rule, value, callback) => {
      if (Number(value) < 0) {
        callback(new Error('最小比例不能小于0'))
      } else {
        callback()
      }
    }
    const validateServiceChargeMax = (rule, value, callback) => {
      if (Number(value) > 100) {
        callback(new Error('比例不能大于100'))
      } else {
        if (this.appForm.serviceChargeMin) {
          if (Number(this.appForm.serviceChargeMin) <= Number(value)) {
            callback()
          } else {
            callback(new Error('最大比例低于最小比例'))
          }
        } else {
          callback()
        }
      }
    }
    const validateRepaymentDay = (rule, value, callback) => {
      if (Number(value) > 28) {
        callback(new Error('还款日不能大于28'))
      } else if (Number(value) < 0) {
        callback(new Error('还款日不能小于0'))
      } else {
        callback()
      }
    }
    return {
      productCode: this.$route.query.productCode,
      appForm: {
        productCode: '',
        sceneName: '',
        productName: '',
        coreId: '',
        coreCode: '',
        coreName: '',
        repaymentMethod: '',
        serviceChargeMethod: '',
        repayment: '',
        maxAmount: '',
        maxPeriod: '',
        penalTYtRateMin: '',
        penalTYtRateMax: '',
        minCycleMin: '',
        minCycleMax: '',
        interestRateMin: '',
        interestRateMax: '',
        overdueMin: '',
        overdueMax: '',
        maxExtensionMin: '',
        maxExtensionMax: '',
        extensionRateMin: '',
        extensionRateMax: '',
        financingMin: '',
        financingMax: '',
        serviceChargeMin: '',
        serviceChargeMax: '',
        repaymentDay: '',
        periods: ''

      },
      showProductInfo: false,
      loadingProduct: {
        detail: false
      },
      loadingTem: {
        list: false
      },
      multipleSelection: undefined,
      productInfo: {},
      capProductList: [],
      coreList: [],
      cityList: [],
      templateList: [],
      setTemplateList: [],
      rules: {
        productCode: [
          { required: true, message: '请选择资方金融产品', trigger: 'blur' }
        ],
        productName: [
          { required: true, message: '请输入场景方金融产品名称', trigger: 'blur' }
        ],
        coreId: [
          { required: true, message: '请选择核心企业', trigger: 'blur' }
        ],
        maxAmount: [
          { required: true, message: '请输入借贷金额上限', trigger: 'blur' }
        ],
        repayment: [
          { required: true, message: '请选择还款方式', trigger: 'blur' }
        ],
        interestRateMin: [
          { required: true, message: '请输入最小日利率', trigger: 'blur' },
          { validator: validatePenaltytMin, trigger: 'blur' }
        ],
        interestRateMax: [
          { required: true, message: '请输入最大日利率', trigger: 'blur' },
          { validator: validatePenaltytMax, trigger: 'blur' }
        ],
        penalTYtRateMin: [
          { required: true, message: '请输入最小罚息', trigger: 'blur' },
          { validator: validateInterestMin, trigger: 'blur' }
        ],
        penalTYtRateMax: [
          { required: true, message: '请输入最大罚息', trigger: 'blur' },
          { validator: validateInterestMax, trigger: 'blur' }
        ],
        minCycleMin: [
          { required: true, message: '请输入最小免收费期限', trigger: 'blur' },
          { validator: validateCycleMin, trigger: 'blur' }
        ],
        minCycleMax: [
          { required: true, message: '请输入最大免收费期限', trigger: 'blur' },
          { validator: validateCycleMax, trigger: 'blur' }
        ],
        overdueMin: [
          { required: true, message: '请输入最小时长', trigger: 'blur' },
          { validator: validateOverdueMin, trigger: 'blur' }
        ],
        overdueMax: [
          { required: true, message: '请输入最大时长', trigger: 'blur' },
          { validator: validateOverdueMax, trigger: 'blur' }
        ],
        maxExtensionMin: [
          { required: true, message: '请输入最小时长', trigger: 'blur' },
          { validator: validateMaxExtensionMin, trigger: 'blur' }
        ],
        maxExtensionMax: [
          { required: true, message: '请输入最大时长', trigger: 'blur' },
          { validator: validateMaxExtensionMax, trigger: 'blur' }
        ],
        extensionRateMin: [
          { required: true, message: '请输入最小宽限期利率', trigger: 'blur' },
          { validator: validateExtensionRateMin, trigger: 'blur' }
        ],
        extensionRateMax: [
          { required: true, message: '请输入最大宽限期利率', trigger: 'blur' },
          { validator: validateExtensionRateMax, trigger: 'blur' }
        ],
        financingMin: [
          { required: true, message: '请输入最小融资比例', trigger: 'blur' },
          { validator: validateFinancingMin, trigger: 'blur' }
        ],
        financingMax: [
          { required: true, message: '请输入最大融资比例', trigger: 'blur' },
          { validator: validateFinancingMax, trigger: 'blur' }
        ],
        serviceChargeMin: [
          { required: true, message: '请输入最小比例', trigger: 'blur' },
          { validator: validateServiceChargeMin, trigger: 'blur' }
        ],
        serviceChargeMax: [
          { required: true, message: '请输入最大比例', trigger: 'blur' },
          { validator: validateServiceChargeMax, trigger: 'blur' }
        ],
      }
    }
  },
  created () {
    if (this.productCode) {
      this.getDetail()
    }
    if (this.viewEdit()) {
      this.getCapProductList()
      this.getCoreInfoList()
    }
  },
  methods: {
    getDetail () {
      this.loading.detail = true
      console.log(1)
      this.api.product.product.getDetail({ productCode: this.productCode }).then(result => {
        const data = result.data.data
        const { productCode, productName, coreId, coreCode, coreName } = data.info
        this.appForm = this._.assign(this.appForm, { productCode, productName, coreId, coreCode, coreName })
        this.productChange(data.info.productCode)
      }).finally(() => {
        this.loading.detail = false
      })
    },
    editStatus () {
      if (this.$route.params.editMode === 'add') {
        return true
      } else {
        if (this.viewEdit()) {
          if (Number(this.$route.query.editType) === 1) {
            return false
          } else if (Number(this.$route.query.editType) === 2) {
            return true
          } else {
            return false
          }
        }
      }
    },
    getCapProductList () {
      this.api.product.product.getListRel().then(result => {
        this.capProductList = result.data.data || []
      }).finally(() => {
      })
    },
    getCoreInfoList () {
      this.api.company.core.getCoreList().then(result => {
        this.coreList = result.data.data || []
      }).finally(() => {
      })
    },
    productChange (val) {
      this.clearProductInfo()
      if (val) {
        const data = this._.find(this.capProductList, { productCode: val }) || {}
        data.sceneName = data.productName
        const { sceneName } = data
        this.appForm = this._.assign(this.appForm, { sceneName })
        this.getProductDetail(val)
      }
    },
    companyChange (val) {
      const data = this._.find(this.coreList, { coreId: val }) || {}
      const { coreCode, coreName } = data
      this.appForm = this._.assign(this.appForm, { coreCode, coreName })
    },
    changeRepayment (val) {
      this.appForm.periods = ''
      if (val !== 'regular') {
        this.rules.periods[0].required = false
        this.rules.repaymentDay[0].required = false
      }
    },
    getProductDetail(productCode) {
      this.loadingProduct.detail = true
      this.api.product.product.getDetail({ productCode: productCode }).then(result => {
        this.showProductInfo = true
        const data = result.data.data
        let overdue = ''
        let maxExtension = ''
        let extensionRate = ''
        let penalTYtRate = ''
        let interestRate = ''
        let minCycle = ''
        let financing = ''
        let serviceCharge = ''
        if (data.info.minCycle) {
          minCycle = JSON.parse(data.info.minCycle)
          data.info.minCycleMin = minCycle.min
          data.info.minCycleMax = minCycle.max
        }
        if (data.info.interestRate) {
          interestRate = JSON.parse(data.info.interestRate)
          data.info.interestRateMin = interestRate.min
          data.info.interestRateMax = interestRate.max
        }
        if (data.info.penaltytRate) {
          penalTYtRate = JSON.parse(data.info.penaltytRate)
          data.info.penalTYtRateMin = penalTYtRate.min
          data.info.penalTYtRateMax = penalTYtRate.max
        }
        if (data.info.overdue) {
          overdue = JSON.parse(data.info.overdue)
          data.info.overdueMin = overdue.min
          data.info.overdueMax = overdue.max
        }
        if (data.info.maxExtension) {
          maxExtension = JSON.parse(data.info.maxExtension)
          data.info.maxExtensionMin = maxExtension.min
          data.info.maxExtensionMax = maxExtension.max
        }
        if (data.info.extensionRate) {
          extensionRate = JSON.parse(data.info.extensionRate)
          data.info.extensionRateMin = extensionRate.min
          data.info.extensionRateMax = extensionRate.max
        }
        if (data.info.financing) {
          financing = JSON.parse(data.info.financing)
          data.info.financingMin = financing.min
          data.info.financingMax = financing.max
        }
        if (data.info.serviceCharge) {
          serviceCharge = JSON.parse(data.info.serviceCharge)
          data.info.serviceChargeMin = serviceCharge.min
          data.info.serviceChargeMax = serviceCharge.max
        }
        this.productInfo = data.info
        data.cityList.forEach(item => {
          this.cityList.push(item.areaCode)
        })
        if (this.editStatus()) {
          this.setTemplateList = data.templateDtos || []
          this.getTemplateList(data.info.capCode)
        } else {
          this.templateList = data.templateDtos
        }
        const { repayment, repaymentMethod, serviceChargeMethod, maxAmount, maxPeriod, penalTYtRateMin, penalTYtRateMax, minCycleMin,
          minCycleMax, interestRateMin, interestRateMax, overdueMin, overdueMax,
          maxExtensionMin, maxExtensionMax, extensionRateMin, extensionRateMax,
          financingMin, financingMax, serviceChargeMin, serviceChargeMax, repaymentDay, periods } = data.info
        this.appForm = this._.assign(this.appForm, { repayment, repaymentMethod, serviceChargeMethod, maxAmount, maxPeriod, penalTYtRateMin, penalTYtRateMax, minCycleMin,
          minCycleMax, interestRateMin, interestRateMax, overdueMin, overdueMax,
          maxExtensionMin, maxExtensionMax, extensionRateMin, extensionRateMax,
          financingMin, financingMax, serviceChargeMin, serviceChargeMax, repaymentDay, periods })
      }).finally(() => {
        this.loadingProduct.detail = false
      })
    },
    getTemplateList (val) {
      this.loadingTem.list = true
      const data = {capCod: val}
      this.api.contract.template.getTemplateList(data).then(result => {
        const data = result.data.data || []
        data.forEach(item => {
          if (!this._.isEmpty(this.setTemplateList)) {
            this.setTemplateList.forEach(val => {
              if (val.templateCode === item.contractTemplateNumber) {
                item.signingMethod = val.signingMethod
              }
            })
          } else {
            item.signingMethod = ''
          }
        })
        this.templateList = data
        this.toggleSelection()
      }).finally(() => {
        this.loadingTem.list = false
      })
    },
    toggleSelection() {
      this.$nextTick(() => {
        this.setTemplateList.forEach(row => {
          this.$refs.multipleTable.toggleRowSelection(
            this.templateList.find(item => { return row.templateCode === item.contractTemplateNumber}, true))
        })
      })
    },
    handleSelectionChange (val) {
      this.multipleSelection = val
    },
    clearProductInfo () {
      this.appForm.maxAmount = ''
      this.appForm.maxPeriod = ''
      this.appForm.penalTYtRateMin = ''
      this.appForm.penalTYtRateMax = ''
      this.appForm.minCycleMin = ''
      this.appForm.minCycleMax = ''
      this.appForm.interestRateMin = ''
      this.appForm.interestRateMax = ''
      this.appForm.overdueMin = ''
      this.appForm.overdueMax = ''
      this.appForm.maxExtensionMin = ''
      this.appForm.maxExtensionMax = ''
      this.appForm.extensionRateMin = ''
      this.appForm.extensionRateMax = ''
      this.appForm.financingMin = ''
      this.appForm.financingMax = ''
      this.appForm.serviceChargeMin = ''
      this.appForm.serviceChargeMax = ''
    },
    submitForms () {
      if (!this.editStatus()) {
        if (!this.multipleSelection || this.multipleSelection.filter(item => item.signingMethod !== '').length === 0) {
          this.$message.error('请选择签署方式')
          return false
        }
      }
      this.$refs.appForm.validate().then(() => {
        const data = this._.cloneDeep(this.appForm)
        const selectCity = this.$refs.city.$refs.areaTree.getCheckedNodes()
        const refsTemplate = this.multipleSelection || []
        const cityList = []
        let templateList = []
        let overdue = ''
        let maxExtension = ''
        let extensionRate = ''
        let penalTYtRate = ''
        let interestRate = ''
        let minCycle = ''
        let financing = ''
        let serviceCharge = ''
        selectCity.forEach((val, index, arr) => {
          if (val.code) {
            const city = {}
            city.areaCode = val.code
            city.areaName = val.name
            cityList.push(city)
          }
        })
        if (!this._.isEmpty(refsTemplate)) {
          refsTemplate.forEach(item => {
            if (item !== undefined) {
              templateList.push({
                id: item.id,
                templateCode: item.contractTemplateNumber,
                signingMethod: item.signingMethod
              })
            }
          })
        }
        if (cityList.length >= 1) {
          this.loading.submit = true
          if (data.minCycleMin && data.minCycleMax) {
            minCycle = JSON.stringify({min: data.minCycleMin, max: data.minCycleMax})
          }
          if (data.interestRateMin && data.interestRateMax) {
            interestRate = JSON.stringify({min: data.interestRateMin, max: data.interestRateMax})
          }
          if (data.penalTYtRateMin && data.penalTYtRateMax) {
            penalTYtRate = JSON.stringify({min: data.penalTYtRateMin, max: data.penalTYtRateMax})
          }
          if (data.overdueMin && data.overdueMax) {
            overdue = JSON.stringify({min: data.overdueMin, max: data.overdueMax})
          }
          if (data.maxExtensionMin && data.maxExtensionMax) {
            maxExtension = JSON.stringify({min: data.maxExtensionMin, max: data.maxExtensionMax})
          }
          if (data.extensionRateMin && data.extensionRateMax) {
            extensionRate = JSON.stringify({min: data.extensionRateMin, max: data.extensionRateMax})
          }
          if (data.financingMin && data.financingMax) {
            financing = JSON.stringify({min: data.financingMin, max: data.financingMax})
          }
          if (data.serviceChargeMin && data.serviceChargeMax) {
            serviceCharge = JSON.stringify({min: data.serviceChargeMin, max: data.serviceChargeMax})
          }
          const product = {
            info: {
              id: this.productInfo.id,
              productCode: data.productCode,
              sceneName: data.sceneName,
              productName: data.productName,
              coreId: data.coreId,
              coreCode: data.coreCode,
              coreName: data.coreName,
              capName: this.productInfo.capName,
              capCode: this.productInfo.capCode,
              capId: this.productInfo.capId,
              repayment: data.repayment,
              repaymentDay: data.repaymentDay,
              productTypeName: this.productInfo.productTypeName,
              repaymentMethod: data.repaymentMethod,
              productFinancing: this.productInfo.productFinancing,
              periods: data.periods,
              minCycle: minCycle,
              interestRate: interestRate,
              overdue: overdue,
              maxExtension: maxExtension,
              extensionRate: extensionRate,
              penaltytRate: penalTYtRate,
              financing: financing,
              serviceCharge: serviceCharge,
              maxAmount: data.maxAmount,
              maxPeriod: data.maxPeriod
            },
            cityList: cityList,
            templateList: templateList
          }
          let api = this.api.product.sceneSide.addScene
          if (this.productCode) {
            api = this.api.product.sceneSide.altScene
          }
          api(product).then(result => {
            if (result.data.success === true) {
              this.loading = false
              this.$message.success('操作成功')
              this.$router.back()
            } else {
              this.$message.error(result.data.message)
            }
          }).catch(e => {
            this.loading.submit = false
          })
        }
      })
    }
  }
}
</script>

<style scoped>

</style>

var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "detail-inner" }, [
    _c(
      "div",
      { staticClass: "function-mian" },
      [
        _c("div", { staticClass: "function-header" }, [_vm._v(" 选择城市： ")]),
        _c("el-tree", {
          directives: [
            {
              name: "loading",
              rawName: "v-loading",
              value: _vm.loading.tab,
              expression: "loading.tab"
            }
          ],
          ref: "areaTree",
          attrs: {
            data: _vm.cityList,
            "node-key": "code",
            "show-checkbox": "",
            "default-checked-keys": _vm.cityInfo,
            "expand-on-click-node": false
          },
          scopedSlots: _vm._u([
            {
              key: "default",
              fn: function(ref) {
                var node = ref.node
                var data = ref.data
                return _c("span", { staticClass: "custom-tree-node" }, [
                  _c("span", { staticClass: "function-tree" }, [
                    _vm._v(_vm._s(data.name))
                  ])
                ])
              }
            }
          ])
        })
      ],
      1
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }